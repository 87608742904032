import './AmazonOauth.css';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';

import { AxiosContext } from '@fstn/react-library';
import { notification } from 'antd';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Select from 'antd/lib/select';
import _ from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Option from 'react-select';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

import { AmazonOAuthError } from './AmazonOAuthError';
import { trackedEvents } from '../../../config/trackedEvents.config';
import { amazonCreateProductProvider, getProductProviderProfiles } from '../../../lib/adsPlateformApi';
import { track } from '../../../lib/analytics';
import { signUp } from '../../../lib/auth';
import { toCamelCaseKeys } from '../../../lib/util';
import SignLayout from '../../layout/Sign/SignLayout';
import { C6Header, gtmButtonStyles, Title } from '../signUp/Theme';

const Style = styled.div`
  color: #6d859e;
  font-size: 14px;
  text-align: center;
  height: 100%;
  display: flex;
  gap: 4em;
  align-items: center;

  .ant-form-item-label {
    text-align: center !important;
  }

  p {
    line-height: 19px;
  }

  a {
    text-decoration: none !important;
  }
`;

/**
 * Page to select the right Amazon Profile after OAuth
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AmazonAttributionProfilePage(props: { style: ?any, accountId?: string, code: string }) {
  const captchaRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [signupError, setSignupError] = useState();
  const { axios, updateToken } = useContext(AxiosContext);
  const [state, updateState] = useImmer({
    profiles: undefined,
    loading: false,
    loaded: false,
    error: undefined,
    productProvider: undefined,
  });
  const theme = localStorage.getItem('signup-theme');

  const load = async () => {
    updateState((draft) => {
      draft.loaded = false;
      draft.loading = true;
    });
    const productProviderString = localStorage.getItem('productProvider');

    if (!productProviderString) {
      window.location.href = '/';
    }

    const productProvider = JSON.parse(productProviderString);

    try {
      const data = await getProductProviderProfiles(
        localStorage,
        axios,
        {
          accountId: props.accountId,
        },
        { productProvider },
      );

      updateState((draft) => {
        draft.productProvider = productProvider;
        draft.profiles = _.orderBy(data.productProviderProfiles, [
          (e) => (e.availableForSelectedMarketplace ? '0' : '1'),
          (e) => (e.accountinfo.id.startsWith('ENTITY') ? '1' : '0'),
        ]);
        draft.loaded = true;
      });
    } catch (error) {
      if (error.response && error.response.data) {
        const { errorMessage } = toCamelCaseKeys(error.response.data);

        notification.error({
          duration: 500,
          message: 'Unable to retrieve your Amazon profiles',
          description: <>{errorMessage}</>,
        });

        updateState((draft) => {
          draft.productProvider = productProvider;
          draft.profiles = [];
          draft.loaded = true;
        });
      } else {
        throw error;
      }
    }
  };

  useEffect(() => {
    load().finally(
      updateState((draft) => {
        draft.loading = false;
      }),
    );
  }, []);
  async function onAttributionProfileSelection(values) {
    try {
      updateState((d) => {
        d.loading = true;
        d.error = undefined;
      });

      const profile = state.profiles.find((p) => p.profileid === values.profile);
      const productProvider = { ...state.productProvider, ...profile };

      productProvider.externalId = `${profile.profileid}`;
      productProvider.externalAccountId = `${profile.accountinfo.id}`;
      productProvider.externalAccountDescriptiveName = `${profile.accountinfo.name}`;
      productProvider.externalAccountResourceName = `${profile.accountinfo.marketplacestringid}`;
      productProvider.currencyCode = `${profile.currencycode}`;
      let { accountId } = props;

      if (isSignUpWithAmazon(props)) {
        // We are on the signup with amazon
        const payload = {
          captcha: values.captcha,
          company: productProvider.userProfile.name,
          email: productProvider.userProfile.email,
          fullname: productProvider.userProfile.name,
          password: Math.random().toString(36).slice(-8),
          loginWithAmazon: true,
        };

        try {
          // if not exist
          await signUp(localStorage, dispatch, axios, payload);
          accountId = localStorage.getItem('selected-account');
          localStorage.setItem('signup-with-amazon', 'true');
        } catch (error) {
          let errorMessage = '';

          if (error.response && error.response.data) {
            errorMessage = toCamelCaseKeys(error.response.data).errorMessage;
          }

          setSignupError(errorMessage);
        }
      }

      await amazonCreateProductProvider(localStorage, axios, { accountId }, { productProvider });
      track(trackedEvents.connectAmazon);

      if (isSignUpWithAmazon(props)) {
        setTimeout(() => {
          window.location.reload();
        }, 200);
      } else {
        if (productProvider.accountinfo.type === 'agency') {
          setTimeout(() => {
            window.location.href = `/${props.accountId}/amazon/account`;
          }, 200);
        } else {
          setTimeout(() => {
            window.location.href = `/${props.accountId}/amazon/account`; // this would go to adsprovider before the widgets work.
          }, 200);
        }
      }
    } finally {
      if (captchaRef && captchaRef.current) {
        captchaRef.current.reset();
      }

      await updateState((d) => {
        d.loading = false;
      });
    }
  }

  function renderError() {
    let err;

    switch (signupError) {
      case 'Override disabled for users':
        err = 'The email linked to this Amazon account already exists';
        break;
      default:
        err = signupError;
    }

    return err;
  }

  if (state.error) {
    return <AmazonOAuthError />;
  }

  if (state.profiles) {
    return (
      <SignLayout
        errorMessage={renderError(signupError)}
        theme={theme}
        header={theme === 'gtm' || theme === 'sign-up-c6' ? <C6Header /> : null}
        title={
          theme === 'gtm' || theme === 'sign-up-c6' ? (
            <Title>Last step: select your profile</Title>
          ) : (
            'Last step: select your profile'
          )
        }
        description={
          <>
            <p>If you manage more than one profile, please add one profile per workspace. </p>
          </>
        }
        footer={
          <Button type="link" onClick={() => history.push(`/${props.accountId}/amazon`)}>
            Switch to another MarketPlace
          </Button>
        }
        image={<RightImage />}
      >
        <Form onFinish={onAttributionProfileSelection} layout="vertical">
          <Form.Item
            name="profile"
            label="Select your Amazon Profile"
            rules={[{ required: true, message: 'Please select an Amazon Profile!' }]}
          >
            <Select size="large" disabled={state.loading}>
              {state.profiles
                .filter((o) => !!o.accountinfo)
                .map((o) => {
                  const disabled = !o.availableForSelectedMarketplace;
                  const legacy = o.accountinfo.id.startsWith('ENTITY');

                  return (
                    <Option key={o.profileid} value={o.profileid} disabled={disabled}>
                      <span style={{ color: legacy && !disabled ? 'rgba(0, 0, 0, 0.55)' : 'inherit' }}>
                        {legacy && <>Legacy Entity </>}
                        {o.accountinfo.name}
                      </span>
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item name="captcha">
            <ReCAPTCHA disabled={state.loading} ref={captchaRef} sitekey={window.env.REACT_APP_CAPTCHA_SITE_KEY} />
          </Form.Item>
          <Button
            block
            loading={state.loading}
            type="primary"
            size="large"
            htmlType="submit"
            style={gtmButtonStyles[theme] || {}}
          >
            Validate
          </Button>
        </Form>
      </SignLayout>
    );
  } else {
    return <></>;
  }
}

function isSignUpWithAmazon(props) {
  return !props.accountId;
}

function RightImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="399"
      height="342"
      fill="none"
      viewBox="0 0 399 342"
      style={{ margin: 'auto' }}
    >
      <g clipPath="url(#clip0)">
        <path
          fill="#E6E6E6"
          d="M29.961 313.85a14.212 14.212 0 0013.704-2.411c4.8-4.024 6.304-10.652 7.528-16.79l3.622-18.157-7.582 5.215c-5.453 3.75-11.028 7.621-14.804 13.053-3.775 5.432-5.422 12.847-2.39 18.725"
        />
        <path
          fill="#F2F2F2"
          d="M31.133 337.095c-.954-6.945-1.936-13.979-1.265-20.998.594-6.234 2.498-12.322 6.375-17.316a28.834 28.834 0 017.398-6.697c.74-.466 1.421.705.684 1.169a27.395 27.395 0 00-10.844 13.069c-2.36 5.998-2.74 12.537-2.333 18.908.245 3.853.768 7.681 1.293 11.505a.699.699 0 01-.474.832.683.683 0 01-.834-.473v.001z"
        />
        <path
          fill="#E6E6E6"
          d="M38.002 325.953a10.452 10.452 0 009.102 4.694c4.609-.219 8.45-3.431 11.907-6.481l10.227-9.02-6.768-.323c-4.868-.233-9.862-.452-14.498 1.049-4.636 1.502-8.912 5.109-9.76 9.902"
        />
        <path
          fill="#F2F2F2"
          d="M28.435 341.089c4.594-8.12 9.923-17.145 19.445-20.029a21.707 21.707 0 018.179-.844c.868.075.651 1.412-.215 1.337a20.177 20.177 0 00-13.053 3.45c-3.68 2.502-6.545 5.981-8.97 9.67-1.486 2.259-2.817 4.615-4.148 6.967-.425.752-1.668.209-1.238-.551z"
        />
        <path
          fill="#fff"
          d="M396.056 240.846H142.76a2.95 2.95 0 01-2.944-2.941V2.941A2.95 2.95 0 01142.76 0h253.296a2.95 2.95 0 012.945 2.94v234.964a2.943 2.943 0 01-2.945 2.94v.001z"
        />
        <path
          fill="#CACACA"
          d="M396.056 240.846H142.76a2.95 2.95 0 01-2.944-2.941V2.941A2.95 2.95 0 01142.76 0h253.296a2.95 2.95 0 012.945 2.94v234.964a2.943 2.943 0 01-2.945 2.94v.001zM142.76 1.174a1.768 1.768 0 00-1.764 1.763v234.968a1.766 1.766 0 001.764 1.763h253.296a1.766 1.766 0 001.765-1.763V2.941a1.765 1.765 0 00-1.765-1.763L142.76 1.174z"
        />
        <path
          fill="#E4E4E4"
          d="M262.446 61.059a2 2 0 00-1.995 1.996 1.995 1.995 0 001.995 1.997H372.12a1.999 1.999 0 001.995-1.997 1.996 1.996 0 00-1.995-1.996H262.446zM262.447 73.035a2 2 0 00-1.994 1.997 1.995 1.995 0 001.994 1.996h55.991a1.994 1.994 0 001.41-3.406 2 2 0 00-1.41-.587h-55.991zM166.588 137.461a2 2 0 00-1.411.586 2 2 0 000 2.82c.374.375.881.586 1.411.587h205.638a1.997 1.997 0 000-3.993H166.588zM166.588 149.441a1.997 1.997 0 000 3.993h151.955a2 2 0 001.994-1.996c0-.529-.21-1.036-.584-1.41a2.005 2.005 0 00-1.41-.587H166.588zM166.588 161.148a1.997 1.997 0 000 3.993h205.638a2 2 0 001.994-1.996c0-.529-.21-1.036-.584-1.41a2.005 2.005 0 00-1.41-.587H166.588zM166.588 173.129a2 2 0 00-1.411.586 2 2 0 000 2.82c.374.375.881.586 1.411.587h151.955a1.997 1.997 0 000-3.993H166.588zM166.588 185.148a1.997 1.997 0 000 3.993h205.638a2 2 0 001.994-1.996c0-.529-.21-1.036-.584-1.41a2.005 2.005 0 00-1.41-.587H166.588zM166.588 197.129a2 2 0 00-1.411.586 2 2 0 000 2.82c.374.375.881.586 1.411.587h151.955a1.997 1.997 0 000-3.993H166.588z"
        />
        <path
          fill="#F8A51B"
          d="M199.16 97.727a28.738 28.738 0 01-15.954-4.834 28.691 28.691 0 01-10.577-12.873 28.658 28.658 0 016.225-31.26 28.727 28.727 0 0114.703-7.85 28.749 28.749 0 0116.592 1.633 28.707 28.707 0 0112.887 10.564 28.663 28.663 0 014.839 15.936 28.695 28.695 0 01-8.42 20.273 28.763 28.763 0 01-20.295 8.411z"
        />
        <path
          fill="#A0616A"
          d="M111.992 206.181a7.172 7.172 0 00-8.763-6.652l-9.492-13.361-9.536 3.781 13.67 18.681a7.209 7.209 0 003.116 4.067 7.222 7.222 0 009.444-1.638 7.205 7.205 0 001.561-4.878z"
        />
        <path
          fill="#3F3D56"
          d="M93.492 205.193l-28.72-37.218 10.76-33.783c.79-8.493 6.11-10.865 6.337-10.962l.345-.148 9.365 24.944-6.876 18.315 16.876 28.351-8.087 10.501z"
        />
        <path
          fill="#A0616A"
          d="M193.234 90.464a7.183 7.183 0 00-5.141 3.23 7.172 7.172 0 00-.82 6.012L174.67 110.19l4.511 9.205 17.59-15.058a7.215 7.215 0 003.818-3.418 7.2 7.2 0 00-2.365-9.278 7.224 7.224 0 00-4.99-1.178z"
        />
        <path
          fill="#3F3D56"
          d="M193.675 108.96l-34.927 31.48-34.552-8.105c-8.539-.128-11.318-5.244-11.432-5.462l-.174-.332 24.173-11.255 18.812 5.431 26.994-18.998 11.106 7.241z"
        />
        <path fill="#A0616A" d="M133.178 332.754l9.384-.001 4.464-36.155h-13.85l.002 36.156z" />
        <path
          fill="#2F2E41"
          d="M160.66 341.457l-29.493.001-.001-11.382 21.219-.001a8.28 8.28 0 015.851 2.421 8.26 8.26 0 012.424 5.844v3.117z"
        />
        <path fill="#A0616A" d="M95.67 332.754l9.383-.001 4.465-36.155h-13.85l.001 36.156z" />
        <path
          fill="#2F2E41"
          d="M123.152 341.457l-29.493.001v-11.382l21.218-.001a8.29 8.29 0 015.851 2.421 8.249 8.249 0 012.424 5.844v3.117zM92.332 200.781l.765 53.521.766 70.342 16.84-1.529 8.42-97.102 10.716 97.102h17.385l1.751-97.866-6.123-21.409-50.52-3.059z"
        />
        <path
          fill="#3F3D56"
          d="M125.292 207.967c-18.323.001-35.189-8.282-35.417-8.396l-.189-.094-1.537-36.86c-.446-1.302-9.226-27.004-10.713-35.173-1.507-8.277 20.329-15.541 22.98-16.39l.602-6.659 24.469-2.633 3.102 8.519 8.778 3.288a4.331 4.331 0 012.691 5.096l-4.879 19.818 11.917 65.571-2.566.111c-6.149 2.774-12.786 3.802-19.238 3.802z"
        />
        <path
          fill="#A0616A"
          d="M122.661 95.391c8.538-4.662 11.676-15.354 7.008-23.883-4.667-8.528-15.371-11.662-23.909-7-8.538 4.662-11.676 15.355-7.009 23.883 4.668 8.528 15.372 11.662 23.91 7z"
        />
        <path
          fill="#2F2E41"
          d="M99.941 96.985c3.358 3.572 9.593 1.654 10.031-3.226.034-.38.032-.76-.008-1.139-.225-2.162-1.476-4.124-1.176-6.407.068-.568.279-1.11.615-1.573 2.676-3.579 8.957 1.6 11.482-1.64 1.548-1.986-.272-5.113.916-7.334 1.568-2.93 6.213-1.485 9.126-3.09 3.241-1.785 3.047-6.752.914-9.773-2.602-3.684-7.164-5.65-11.669-5.934-4.504-.283-8.978.933-13.184 2.57-4.779 1.86-9.517 4.431-12.458 8.628-3.576 5.105-3.92 11.967-2.132 17.935 1.089 3.63 4.802 8.065 7.543 10.983z"
        />
        <path
          fill="#CACACA"
          d="M223.868 341.909H.586a.586.586 0 110-1.171h223.282a.584.584 0 01.414 1 .587.587 0 01-.414.171z"
        />
        <path
          fill="#fff"
          d="M197.502 79.877a2.046 2.046 0 01-1.395-.548l-9.469-8.785a2.048 2.048 0 011.321-3.547 2.053 2.053 0 011.471.544l8.02 7.442 15.868-15.85a2.053 2.053 0 013.507 1.447 2.037 2.037 0 01-.606 1.45l-17.266 17.247a2.04 2.04 0 01-1.451.6z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H399V342H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
